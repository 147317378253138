.footer-wrapper {
  margin-top: 5px;
  position: relative;
  width: 100%; /* Adjust as needed */
  overflow: hidden; /* Ensure no blur bleeds out */
  padding: 28px 18px;
  @media (max-width: 992px) {
    margin-left: 0;
  }
  .footer-container {
    padding:0 60px;
    @media (max-width: 768px) {
      padding:0 10px;

    }

    .para {
      p {
        font-size: 11px;
        line-height: 18px;
        color: var(--search-clr);
        text-align: center;
        @media (max-width: 992px) {
          display: none;
        }
      }
    }

    .footer-content {
      justify-content: space-between;
      @media (max-width: 992px) {
        margin-top: 0;
      }
      @media (max-width: 872px) {
        flex-direction: column-reverse;
      }

      .media {
        gap: 14px;
        @media (max-width: 872px) {
          margin-top: 14px;
          margin-bottom: 10px;
        }
        a {
          color: #fff;
          cursor: pointer;
          svg {
            width: 18px;
            color: var(--text-primary-clr);
          }
        }
      }
      .footer-second-content {
        gap: 18px;
        @media (max-width: 992px) {
          display: block;
        }
        .app-icon {
          gap: 2px;
          cursor: pointer;
          @media (max-width: 992px) {
            order: 1;
          }
        }
        .list-navigation {
          .links {
            gap: 18px;
            @media (max-width: 670px) {
              display: grid;
              grid-template-columns: 1fr 1fr;
              gap: 6px;
              margin-bottom: 14px;
            }
            a {
              color: var(--text-secondary-clr);
              font-size: 13px;
              &:hover {
                color: var(--footer-text-hover-clr);
              }
            }
          }
          p {
            color: var(--search-clr);
            font-size: 12px;
            margin-top: 8px;

            span a {
              color: var(--text-secondary-clr);
              &:hover {
                color: var(--footer-text-hover-clr);
              }
            }
          }
        }
      }
    }
  }
}


.footer-grid{
  display:grid;
  padding:0 100px;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1.75fr;
  padding-bottom:20px;
}




.footer-wrapper::before,
.footer-wrapper::after {
  content: '';
  position: absolute;
  border-radius: 50%; /* Makes it circular */
  filter: blur(50px); /* Creates the blur effect */
}



.footer-wrapper::after {
  width: 350px; /* Larger size for the blue spot */
  height: 350px;
  z-index: 0;
  background: rgb(117 245 85 / 23%); /* Blue color */
  bottom: 0;
  right: 0;
  transform: translate(50%, 50%) rotate(45deg);
}


.custom-hr {
  margin: 0 50px 20px;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-bottom: 1px solid #464646;
}



@media (max-width: 768px) {
   .footer-grid {
    padding:0px;
    gap:20px;
    padding-bottom:20px;

    grid-template-columns: 1fr 1fr; /* Two columns layout for the first rows */
    grid-template-rows: auto auto; /* Adjust row height automatically */
  }

  .footer-grid > :nth-child(6) {
    grid-column: span 2; /* Make the last item span across both columns */
  }

  .custom-hr {
    margin: 0 10px 20px;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    border-bottom: 1px solid #464646;
  }
  
}

.sdsdsd{
  position: relative;
  width: 100%; /* Adjust as needed */
  overflow: hidden; /* Ensure no blur bleeds out */
}


.sdsdsd::before,
.sdsdsd::after {
  content: '';
  position: absolute;
  border-radius: 50%; /* Makes it circular */
  filter: blur(50px); /* Creates the blur effect */
}



.sdsdsd::before {
  width: 150px; /* Size of the red spot */
  height: 150px; /* Size of the red spot */
  background: #f18e0d98; /* Orange color */
  bottom: 20%;
  left: -5%;
  transform: translateY(-50%);
}