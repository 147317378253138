/* Container for the Telegram Icon */
.telegram-icon-container {
    position: fixed;
    bottom: 20px;
    right: 20px;
    cursor: pointer;
    z-index: 1000;
    display: flex;
    align-items: center;
  }
  
  /* Telegram Icon Styles */
  .telegram-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #0088cc;
    color: white;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    text-decoration: none;
    transition: transform 0.3s ease, background-color 0.3s ease;
  }
  

  
  /* Animated Text Styles */
  .animated-text {
    position: absolute;
    bottom: 13px;
    right: 70px; /* Positioned to the left of the icon */
    background-color: #0088cc;
    color: white;
    padding: 8px 12px;
    border-radius: 5px;
    white-space: nowrap;
    opacity: 0;
    transform: translateX(50px); /* Hidden to the right initially */
    transition: opacity 0.3s ease, transform 0.3s ease;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    font-size: 14px;
  }
  
  /* Visible State for the Text */
  .animated-text.visible {
    opacity: 1;
    transform: translateX(0); /* Slides into view */
  }
  