.hero-wrapper {
  @media (max-width: 992px) {
    padding: 0;
  }

}
.box-wrapperssss {
  width: 100%;
  height: 90vh;
  background-color: black;
  background-image: 
    linear-gradient(to bottom, rgba(0, 0, 0, 0) 80%, rgba(0, 0, 0, 1)),
    url(../../assets/images/heading2.png);
  background-size: cover;
  background-repeat: no-repeat;
}

.box-wrapperss {
  width: 100%;
  height: 90vh;
  background-color: black;
  background-image: 
    linear-gradient(to bottom, rgba(0, 0, 0, 0) 80%, rgba(0, 0, 0, 1)),
    url(../../assets/images/hiring-back.png);
  background-size: cover;
  background-repeat: no-repeat;
}

@media (max-width: 992px) {
  .box-wrappers {
    height: fit-content;
  }
}


.innerhead{
  font-weight: 400;
  font-size: 30px;
}
.searbar {
  background: var(--drop-down-clr);
  border-radius: 12px;
  max-width: 600px;
  max-height: 40px;
  margin-top: 10px;
  width: 100%;
  @media (max-width: 992px) {
  }

  .searchBar-icon {
    padding: 6px 12px;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    cursor: pointer;
    svg {
      width: 14px;
      color: var(--icon-clr);
      &.hover{
        color:#ffba00;
      }
    }
  }
  input {
    outline: none;
    border: none;
    background: transparent;
    padding: 12px 12px;
    color: var(--white-clr);
    width: 100%;
    font-size: 13px;
    &::placeholder {
      color: var(--search-clr);
    }
     /* Styles for the .searbar when input inside it is in focus */
  

  }
}


.searchBar-icon svg:hover{
  color:#ffba00;
}


.searbar.focus-within {
  border: 1px solid #ffba00; /* Add the border color when input inside .searbar is focused */
}


.row-mained{
  display:grid;
  grid-template-columns:65% 35%
}


.rowed{
  margin-top: 20px;
  
  width:100%;
}

.row-grid-wrapper{
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap:20px;
}



.layer1{
  background-color:  var(--drop-down-clr);
  border-radius: 15px;
  padding: 25px 30px;
}

.layer2{
  background-color:  var(--drop-down-clr);
  border-radius: 15px;
  padding: 25px 00px 10px;
}

.tbaloo{
  padding: 0px 30px;

}

.layer1 p{
  font-family: var(--font-medium);

  font-size: 13px;

}


.layer2 p{
  font-family: var(--font-medium);
  padding-right: 30px;

  font-size: 13px;

}
.layer1 svg{
  width: 13px;
  color:#ffcf4b

}

.troll{
  font-family: var(--font-medium);
  color:#ffcf4b;
margin-top: 2px;
margin-left: 4px;
  font-size: 13px;
}

.iconname{
  display: flex;
}


.iconnamed{
  display: flex;
}


.layer1 h3{
  font-size: 30px;
  font-family: var(--font-medium);
font-weight: 400;
  color: #ffba00;
}



.layer2 h3{
  font-size: 30px;
  margin-right: 30px;
  font-family: var(--font-medium);
font-weight: 400;
  color: #ffba00;
}



.mapvalidate{
  display:grid;
  grid-template-columns:75% 25%;
}


/* RadialProgressBar.css */
.radial-progress {
  width: 150px;
  height: 150px;
  position: relative;
  background-color: #f0f0f0;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
}

.radial-progress .circle {
  width: 100%;
  height: 100%;
  background: conic-gradient(green 0% 25%, transparent 25% 100%);
  clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
  border-radius: 50%;
  transition: transform 0.5s ease-in-out;
}


.markqee{
  margin-top:-50px;
}
.radial-progress .text {
  position: absolute;
  font-size: 20px;
  font-weight: bold;
  color: #333;
}


.circular-progress-container {
  .CircularProgressbar-trail {
    stroke-width: 2px;
  }
  .CircularProgressbar-stroke {
    stroke-width: 2px;
  }
}





@media (max-width: 768px) {
  .row-mained{
    display:grid;
    grid-template-columns:100%
  }


  .mobhide{
    display:none;
  }


  .markqee{
    margin-top:0px;
  }

  .box-wrapper{
    width:100%;
    height:fit-content;
    background-color: black;
    background-image: url(../../assets/images/baaz-wallpaper.png);

    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 20px;
    padding:30px 20px;
  }

  .mapvalidate{
    display:grid;
    grid-template-columns:70% 30%;
  }
  

  .row-grid-wrapper{
    display: grid;
    grid-template-columns: 1fr;
    gap:20px;
  }

}


.waves-background {
  position: relative;
  overflow:hidden;
}

.waves-background canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}






.box-wrappersssss {
  width: 100%;
  height: 90vh;
  background-color: black;
  background-image: 
    linear-gradient(to bottom, rgba(0, 0, 0, 0) 80%, rgba(0, 0, 0, 1)),
    url(../../assets/images/privacybg.png) !important;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}