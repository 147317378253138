@font-face {
  font-family: "Poppins-Bold";
  src: url("../src/assets/fonts/Poppins-Bold.ttf");
}
@font-face {
  font-family: "Poppins-Light";
  src: url("../src/assets/fonts/Poppins-Light.ttf");
}
@font-face {
  font-family: "Poppins-Regular";
  src: url("../src/assets/fonts/Poppins-Regular.ttf");
}
@font-face {
  font-family: "Poppins-SemiBold";
  src: url("../src/assets/fonts/Poppins-SemiBold.ttf");
}
@font-face {
  font-family: "Poppins-Medium";
  src: url("../src/assets/fonts/Poppins-Medium.ttf");
}

@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";


:root {
  --font-bold: "Poppins-Bold";
  --font-light: "Poppins-Light";
  --font-semiBold: "Poppins-SemiBold";
  --font-medium: "Poppins-Medium";
  --font-regular: "Poppins-Regular";

  --drop-down-bg-clr: #000616;
  --search-border-clr: #3a4956;
  --search-btn-clr: #3a4956;
  --search-clr: #818ea3;
  --text-secondary-clr: #ffba00;
  --text-primary-clr: #cacedb;
  --footer-text-hover-clr: #0056b3;
  --green-clr: #17c671;
  --dropdown-bg: #23323c;
  --light-green: #cdffe7;

  // ***************
  // --navigation-clr: #142028;
  --drop-down-clr: #23323c;
  --white-clr: #fff;
  --background: #141f27;
  --search-icon-bg-clr: #3a4956;
  --icon-clr: #818ea3;
  --secondary-clr: #818ea3;
  --over-all-bg-clr: #000;
  --light-clr: #142028;
  --side-bar-clr: #0c1318;
  --icon-secondary-clr: #818ea3;
  --light-green: #cdffe7;
}

*,
::after,
::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background: var(--over-all-bg-clr);
  color: var(--white-clr);
  overflow-x: hidden;
  font-family: var(--font-regular);
}

body.light {
  // ********

  --font-bold: "Poppins-Bold";
  --font-light: "Poppins-Light";
  --font-semiBold: "Poppins-SemiBold";
  --font-medium: "Poppins-Medium";
  --font-regular: "Poppins-Regular";

  --drop-down-bg-clr: #000616;
  --search-border-clr: #3a4956;
  --search-btn-clr: #3a4956;
  --search-clr: #818ea3;
  --text-secondary-clr: #ffba00;
  --text-primary-clr: #cacedb;
  --footer-text-hover-clr: #0056b3;
  --green-clr: #17c671;
  --dropdown-bg: #23323c;
  --light-green: #cdffe7;

  // ***************
  // --navigation-clr: #142028;
  --drop-down-clr: #23323c;
  --white-clr: #fff;
  --background: #142028;
  --search-icon-bg-clr: #3a4956;
  --icon-clr: #818ea3;
  --secondary-clr: #818ea3;
  --over-all-bg-clr: #000;
  --light-clr: #142028;
  --side-bar-clr: #0c1318;
  --icon-secondary-clr: #818ea3;
  --light-green: #cdffe7;
}

html {
  scroll-behavior: smooth;
}

.mx {
  max-width: 1500px;
  width: 100%;
  margin: 0 auto;
}

button:disabled {
  cursor: no-drop;
}

.flex-item {
  display: flex;
  align-items: center;
}
a {
  text-decoration: none;
  color: #fff;
}

.pad {
  padding: 0 18px;
}

p {
  color: var(--search-clr);
  font-size: 12px;
}

.layout-content {
  margin-left: 0px;
  @media (max-width: 992px) {
    margin-left: 0;
  }
}

.tab-container {
  list-style-type: none;
  margin: 0;
  -webkit-margin-before: 0;
  margin-block-start: 0;
  -webkit-margin-after: 0;
  margin-block-end: 0;
  -webkit-padding-start: 0;
  padding-inline-start: 0;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  max-width: 100%;
  border-radius: 0.5rem;

  li {
    position: relative;

    &.active {
      button {
        color: #ffba00;
        background-color: var(--over-all-bg-clr);
      }
    }

    button {
      border-radius: 0.5rem;
      border: none;
      padding: 0.56875rem 0.65rem;
      margin: 0.1875rem;
      background-color: transparent;
      border-radius: 0.8125rem;
      font-style: normal;
      -moz-osx-font-smoothing: grayscale;
      font-weight: 500;
      line-height: 16px;
      color: #818ea3;
      font-size: 13px;
      white-space: nowrap;
      transition: all 200ms linear;
      cursor: pointer;
      text-transform: capitalize;

      &:hover {
        color: #ffba00;
        background-color: var(--over-all-bg-clr);
      }
    }
  }
}


.apoplp{
  background-image: url("./assets/images/RectTwo.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.apoplo{
  background-image: url("./assets/images/RectangleHiring.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 80%!important; 
}



.apoplsp{
  background-image: url("./assets/images/RectThree.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}


.apoplps {
  position: relative;
  width: 100%; /* Adjust as needed */
  overflow: hidden; /* Ensure no blur bleeds out */
}

.apoplps::before,
.apoplps::after {
  content: '';
  position: absolute;
  width: 150px; /* Size of the red spot */
  height: 150px; /* Size of the red spot */
  background: #ea1c798f;
  filter: blur(50px); /* Creates the blur effect */
  border-radius: 50%; /* Makes it circular */
}

.apoplps::before {
  bottom: 20%;
  left: -5%;
  transform: translateY(-50%);
}

.apoplps::after {
  top: 0;
  right: 0;
  transform: translate(50%, 50%) rotate(45deg);
}

@media (max-width: 600px) {
  .apoplps::before,
  .apoplps::after {
    width: 100px; /* Adjust the size for mobile */
    height: 100px; /* Adjust the size for mobile */
  }

  .apoplps::before {
    bottom: 10%; /* Adjust position for mobile */
    left: -10%;
  }

  .apoplps::after {
    top: 10%;
    right: 10%;
    transform: translate(30%, 30%) rotate(45deg);
  }
}


.apoplpss {
  position: relative;
  width: 100%; /* Adjust as needed */
  overflow: hidden; /* Ensure no blur bleeds out */
}

.apoplpss::before,
.apoplpss::after {
  content: '';
  position: absolute;
  border-radius: 50%; /* Makes it circular */
  filter: blur(50px); /* Creates the blur effect */
}

.apoplpss::before {
  width: 150px; /* Size of the red spot */
  height: 150px; /* Size of the red spot */
  background: #f18e0d98; /* Orange color */
  bottom: 20%;
  left: -5%;
  transform: translateY(-50%);
}

.apoplpss::after {
  width: 100px; /* Larger size for the blue spot */
  height: 100px;
  background: #007bffb7; /* Blue color */
  top: 0;
  right: 0;
  transform: translate(50%, 50%) rotate(45deg);
}


.stats-back{
  background-image: url("./assets/images/back-stat.png");
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  display:flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
}